
<template>
  <default-layout class="introduction-page">
    <section class="introduction-sect__overview">
      <h1>{{ $t("introduction.header") }}</h1>
      <img src="~@/assets/tapthe.jpg" alt="intro-image" />
      <p v-html="$t('introduction.overview')"></p>
    </section>
    <section class="mission-sect">
      <img src="~@/assets/1.jpg" alt="intro-image" />
      <div class="mission-sect__card">
        <h1>{{ $t("introduction.mission.header") }}</h1>
        <p>{{ $t("introduction.mission.content") }}</p>
      </div>
    </section>
    <section class="vision-sect">
      <img src="~@/assets/3.jpg" alt="intro-image" />
      <div class="vision-sect__card">
        <h1>{{ $t("introduction.vision.header") }}</h1>
        <p>{{ $t("introduction.vision.content") }}</p>
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";
export default {
  components: {
    DefaultLayout,
  },
  mounted() {
    // eslint-disable-next-line no-undef
    let missionTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: ".mission-sect",
        start: "top center",
        toggleActions: "restart none none none",
      },
    });
    missionTimeLine
      .from(".mission-sect img", { opacity: 0, x: -100, duration: 1 })
      .from(".mission-sect__card", { x: 100, duration: 1 }, "-=1");

    // eslint-disable-next-line no-undef
    let visionTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: ".vision-sect",
        start: "top center",
        toggleActions: "restart none none none",
      },
    });
    visionTimeLine
      .from(".vision-sect img", { opacity: 0, x: -100, duration: 1 })
      .from(".vision-sect__card", { x: 100, duration: 1 }, "-=1");
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/introduction.scss";
</style>